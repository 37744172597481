import { Link as RemixLink } from "@remix-run/react";
import type { RemixLinkProps } from "@remix-run/react/dist/components";
import { cva, type VariantProps } from "class-variance-authority";
import { forwardRef } from "react";
import { cn } from "~/lib/utils";

export const linkVariants = cva("", {
  variants: {
    variant: {
      default: "",
      header:
        "uppercase font-light tracking-widest hover:text-stone-700 dark:hover:text-stone-400 transition-colors",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface LinkProps
  extends RemixLinkProps,
    React.RefAttributes<HTMLAnchorElement>,
    VariantProps<typeof linkVariants> {}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ variant, className, children, ...props }, ref) => {
    return (
      <RemixLink
        className={cn(linkVariants({ variant, className }))}
        {...props}
      >
        {children}
      </RemixLink>
    );
  }
);

Link.displayName = "Link";
